import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useRef } from "react";

const getPlaceFromSpot = (spot, judgeId, athleteId) =>
    spot.getIn(["result", "rides", "" + (athleteId || spot.getIn(["athlete", "id"])), 0, "scores", "" + judgeId]);

const getModifierFromSpot = (spot, athleteId) =>
    spot.getIn(["result", "rides", "" + (athleteId || spot.getIn(["athlete", "id"])), 0, "modifier"])?.toJS();

const getCompetitorsForIndividualHeat = (spots, judgeId) => spots.filter(s => s).toArray().map(spot => ({
    competitorId: spot.getIn(["athlete", "id"]),
    athleteId: spot.getIn(["athlete", "id"]),
    athleteIds: [spot.getIn(["athlete", "id"])],
    athlete: {
        name: spot.getIn(["athlete", "name"]),
        teamName: spot.getIn(["athlete", "team_name"]),
    },
    physicalTagIds: [spot.getIn(["athlete", "physical_tag_id"])].filter(t => t),
    place: getPlaceFromSpot(spot, judgeId),
    modifier: getModifierFromSpot(spot)
}));

const getCompetitorsForTeamLevelTeamHeat = (spots, judgeId) => spots.filter(s => s).toArray().map(spot => ({
    competitorId: spot.getIn(["athlete", "id"]),
    athleteId: spot.getIn(["athlete", "id"]),
    athleteIds: spot.getIn(["athlete", "athletes"]).map(a => a.get("id")).toJS(),
    athlete: {
        name: spot.getIn(["athlete", "name"]),
        teamName: spot.getIn(["athlete", "athletes"]).map(a => a.get("name")).join(", "),
    },
    physicalTagIds: spot.getIn(["athlete", "athletes"]).map(a => a.get("physical_tag_id")).filter(t => t).toJS(),
    place: getPlaceFromSpot(spot, judgeId),
    modifier: getModifierFromSpot(spot)
}));

const getCompetitorsForIndividualLevelTeamHeat = (spots, judgeId) => spots.filter(s => s).map(spot => spot.getIn(["athlete", "athletes"]).map(teamMember => ({
    competitorId: spot.getIn(["athlete", "id"]),
    athleteId: teamMember.get("id"),
    athleteIds: [teamMember.get("id")],
    athlete: {
        name: teamMember.get("name"),
        teamName: spot.getIn(["athlete", "name"]),
    },
    physicalTagIds: [teamMember.get("physical_tag_id")].filter(t => t),
    place: getPlaceFromSpot(spot, judgeId, teamMember.get("id")),
    modifier: getModifierFromSpot(spot, teamMember.get("id"))
}))).flatten(1).toJS();

const getMapperForHeat = heat => {
    if (!heat.getIn(["config", "is_teams"]))
        return getCompetitorsForIndividualHeat;

    if (heat.getIn(["config", "team_config", "appraisal_level"]) === "team")
        return getCompetitorsForTeamLevelTeamHeat;

    return getCompetitorsForIndividualLevelTeamHeat;
};

export const getNextPlace = ({ reverse, competitors }) =>
    reverse
        ? Math.max(Math.min(...competitors.map(competitor => competitor.place || (competitors.length + 1))) - 1, 1)
        : Math.max(0, ...competitors.map(competitor => competitor.place || 0)) + 1;

export const useCompetitorsToRecord = ({ immutableSpots, heat }) => {
    const { currentUser: { id: judgeId } } = useCurrentUser();

    const competitors = useRef();

    if (!competitors.current)
        competitors.current = getMapperForHeat(heat)(immutableSpots, judgeId);

    const resetCompetitors = () => competitors.current = null;
    const nextPlace = getNextPlace({ reverse: heat.getIn(["config", "calculator_options", "reverse"]), competitors: competitors.current });

    return { competitors: competitors.current, nextPlace, resetCompetitors };
};
