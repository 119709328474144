import dayjs from "dayjs";
import { Series } from "./Series";
import { SHORT_DATE, SHORT_DATE_WITHOUT_YEAR } from "utils/dates";

export const Event = eventData => ({
    ...eventData,
    rankingsSeries() {
        return eventData.series.filter(series => Series(series).isRankingsSeries());
    },
    shouldHideFinals() {
        return eventData.hideFinals && eventData.status !== "results_published";
    },
    isCompleted() {
        return ["finished", "results_published", "cancelled"].includes(eventData.status);
    },
    isLive() {
        return eventData.status === "on";
    },
    eventDate(shortFormat = false) {
        const startDate = dayjs(eventData.date);
        const endDate = dayjs(eventData.date).add((eventData.daysWindow || 1) - 1, "day");
        const dateSuffixFormat = shortFormat ? "MMM" : "MMMM YYYY";

        let text = startDate.format("D");
        startDate.unix() === endDate.unix() ?
            text += ` ${startDate.format(dateSuffixFormat)}` :
            shortFormat ?
                text += ` ${startDate.format("MMM")} - ${endDate.format(SHORT_DATE_WITHOUT_YEAR)}` :
                startDate.month() === endDate.month() ?
                    text += ` - ${endDate.format("D")} ${startDate.format("MMMM YYYY")}` :
                    text += ` ${startDate.format("MMM")} - ${endDate.format(SHORT_DATE)}`;

        return text;
    },
    isPublished() {
        return ["published", "on", "on_hold", "finished", "results_published"].includes(eventData.status);
    },
    utcOffset() {
        return eventData.location?.utcOffsetMinutes ?? dayjs().utcOffset();
    }
});
