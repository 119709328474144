import { addAthlete } from "../../../../../actions/judge";
import { get as getHeat } from "../../../../../actions/heat";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

export const useAddAthleteAndRecord = ({ heatId, position, setDisappearingEvent, recordNextPlace, resetCompetitors }) => {
    const { id: eventId } = useParams();
    const dispatch = useDispatch();

    return useCallback((athleteId, tagId, eventDivisionId) => {
        return dispatch(addAthlete(eventId, heatId, {
            athlete_id: athleteId,
            physical_tag_id: tagId,
            position
        }, false, eventDivisionId))
            .then(() => recordNextPlace(athleteId, athleteId))
            .then(() => dispatch(getHeat(eventId, heatId, false)))
            .then(() => {
                resetCompetitors();
                setDisappearingEvent({ type: "success" });
            });
    }, [recordNextPlace, heatId]);
};

