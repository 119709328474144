import { gql } from "@apollo/client";

export const GET_PHYSICAL_TAG = gql`
  query getPhysicalTag($id: ID, $humanReadableId: ID) {
    physicalTag(id: $id, humanReadableId: $humanReadableId) {
      id
      humanReadableId
      athleteId
      athlete {
        id
        name
        image
        dob
      }
    }
  }
`;

export const LINK_TAG_TO_ATHLETE = gql`
  mutation linkTagToAthlete($serialNumber: ID!, $humanReadableId: ID, $athleteId: ID!) {
    linkTagToAthlete(input: {serialNumber: $serialNumber, humanReadableId: $humanReadableId, athleteId: $athleteId}) {
      tag {
        id
        athlete {
            id
            physicalTags {
                id
            }
        }
      }
    }
  }
`;
