import { useCallback, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, Dialog } from "@mui/material";
import { Watch } from "@mui/icons-material";
import { useNFC } from "../../../../nfc/useNFC";
import { useNFCEvents } from "../../../../nfc/useNFCEvents";
import { GET_PHYSICAL_TAG } from "../../../../../graphql/physicalTag";
import { T } from "../../../../util/t";
import { NFCNotSupported } from "../../../../layout/ErrorPages";
import { ScanResultsDialog } from "./ScanResultsDialog";
import { useRecordNextPlace } from "../../../../layout/heat/edit/useRecordNextPlace";
import { useCompetitorsToRecord } from "../../../../layout/heat/edit/useCompetitorsToRecord";
import { useAddAthleteAndRecord } from "./useAddAthleteAndRecord";
import { useEvent } from "../EventProvider";
import { useDialogState } from "../../../../../hooks/useDialogState";

export const ScanResultsWidget = ({ heat, spots }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    return (
        <>
            <Button fullWidth variant="outlined" onClick={openDialog} startIcon={<Watch/>}>
                <T>Scan to record</T>
            </Button>
            <Dialog fullScreen open={dialogOpen} onClose={closeDialog}>
                <ScanResultsWidgetDialog heat={heat} closeDialog={closeDialog} immutableSpots={spots}/>
            </Dialog>
        </>
    );
};

export const ScanResultsWidgetDialog = ({ heat, immutableSpots, closeDialog }) => {
    const  eventToUse  = useEvent();
    const heatId = heat.get("id");
    const athletes = heat.get("athletes");
    const eventDivisions = eventToUse.get("event_divisions").find(ed => ed.get("id") === heat.get("event_division_id")).get("event_divisions");
    const position = athletes.map(a => a.get("position")).max() + 1 || 0;
    const eventDivisionId = heat.get("event_division_id");

    const { competitors, nextPlace, resetCompetitors } = useCompetitorsToRecord({ immutableSpots, heat });
    const [event, setEvent, setDisappearingEvent, clearEvent] = useNFCEvents();
    const recordNextPlace = useRecordNextPlace({ heatId, nextPlace });
    const addAndRecord = useAddAthleteAndRecord({ heatId, position, setDisappearingEvent, recordNextPlace, resetCompetitors });
    const scanToAdd = heat.getIn(["config", "unmarshalled"])
        && heat.get("round_position") === 0
        && eventDivisions.size === 0
        && !heat.getIn(["config", "is_teams"]);

    const recordNextPlaceIfNeeded = useCallback(competitor => {
        if (competitor.place) return Promise.resolve(setDisappearingEvent({ type: "success" }));

        competitor.place = nextPlace;
        return recordNextPlace(competitor.competitorId, competitor.athleteId)
            .then(() => setDisappearingEvent({ type: "success" }))
            .catch(e => {
                setDisappearingEvent({ type: "warning" });
                throw e;
            });
    }, [recordNextPlace]);


    const client = useApolloClient();

    const onReading = useCallback(({ serialNumber, humanReadableId }) => {
        const competitor = competitors.find(competitor => competitor.physicalTagIds.some(id => id === serialNumber));
        return competitor
            ? recordNextPlaceIfNeeded(competitor)
            : client
                .query({ query: GET_PHYSICAL_TAG, variables: { id: serialNumber, humanReadableId }, fetchPolicy: "network-only" })
                .then(({ data }) => {
                    const athlete = data?.physicalTag?.athlete;
                    const competitor = competitors.find(competitor => competitor.athleteIds.some(id => "" + id === athlete?.id));
                    return competitor
                        ? recordNextPlaceIfNeeded(competitor)
                        : scanToAdd
                            ? addAndRecord(athlete?.id, serialNumber, eventDivisionId)
                            : Promise.reject(setEvent({ type: "error", athlete, tagId: serialNumber, humanReadableId }));
                });
    }, [competitors, recordNextPlaceIfNeeded]);

    const onReadingError = useCallback(() => setDisappearingEvent({ type: "warning" }), []);
    const onBadTag = useCallback(() => setEvent({ type: "error" }), []);
    const onLoading = useCallback(() => setEvent({ type: "loading" }), []);

    const { nfcEnabled, startScanning } = useNFC({ onReading, onReadingError, onBadTag, onLoading });

    useEffect(() => {
        if (nfcEnabled && startScanning) {
            startScanning();
        }
    }, [nfcEnabled, startScanning]);

    return nfcEnabled ?
        <ScanResultsDialog
            heat={heat}
            competitors={competitors}
            nextPlace={nextPlace}
            resetCompetitors={resetCompetitors}
            event={event}
            clearEvent={clearEvent}
            setDisappearingEvent={setDisappearingEvent}
            closeDialog={closeDialog}
        /> :
        <NFCNotSupported closeDialog={closeDialog}/>;
};
