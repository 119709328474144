import { Field } from "redux-form/immutable";
import { FormField, SearchSelectInput } from "../../../forms";
import { T } from "../../../util/t";
import { useLocationField } from "../new/hooks/useLocationField";
import { formatTimezone } from "../../../../utils/utils";
import { Typography } from "@mui/material";
import { makeStyles } from "../../../providers/makeStyles";
import Immutable from "immutable";

const useLocationStyles = makeStyles(theme => ({
    location: {
        ".formField": {
            marginBottom: 0,
            "input": {
                border: 0,
                backgroundColor: theme.palette.background.paper,
                "&.MuiAutocomplete-input": {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
                "&:focus": {
                    outline: 0
                }
            },
            ".MuiAutocomplete-root": {
                backgroundColor: theme.palette.background.paper,
                [theme.breakpoints.up("md")]: {
                    width: "60%"
                },
            },
            ".error-message":{
                paddingBottom: theme.spacing(2.5)
            }
        }
    }
}));

export const LocationInput = ({ name, formState, change }) => {
    const dateField = formState.get("start_date");
    const { loading, options, autocompleteProps } = useLocationField({ value: formState.get(name), setValue: value => change(name, Immutable.fromJS(value)), getValue: value => value?.toJS(), getInitialValueLabel: value => value?.get("placePredictionText"), dateField });
    const classes = useLocationStyles();
    const utcOffsetMinutes = formState.getIn([name,"utcOffsetMinutes"]),
        timezone = formatTimezone(utcOffsetMinutes, formState.getIn([name,"timezone","dstOffsetMinutes"]), formState.getIn([name,"timezone","abbreviation"]));

    return (
        <FormField className={classes.location}>
            <Field name={name}
                   component={SearchSelectInput}
                   helpText="location_tooltip_text"
                   label={<T>Location:</T>}
                   loading={loading}
                   options={options}
                   autocompleteProps={autocompleteProps} />
            {timezone && <Typography variant="caption">
                {timezone}
            </Typography>}
        </FormField>
    );
};
