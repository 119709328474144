import { createElement, PureComponent } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, getFormSyncErrors, getFormValues } from "redux-form/immutable";
import { Tab, Tabs } from "@mui/material";

import { PaymentSetupForm } from "../../forms/paymentSetupForm";
import { LoadingButton } from "../../actions/loadingButton";
import { T } from "../../util/t";
import { mapFamilyTotalToForm, mapDivisionsToForm } from "./helpers";
import { DeleteEventWidget } from "../../layout/event/edit/DeleteEventWidget";
import { EventAdvancedForm } from "../../layout/event/edit/EventAdvancedForm";
import { EventJudgingForm } from "../../layout/event/edit/EventJudgingForm";
import { EventGeneralForm } from "../../layout/event/edit/EventGeneralForm";
import { RegistrationSetupForm } from "../../layout/registrationSettings/RegistrationSetupForm";
import { RankingsLeaderboardsForm } from "../../layout/event/edit/RankingsLeaderboardsForm";
import { ISO_DATE } from "utils/dates";

const selector = formValueSelector("event");

@connect((state, props) => {
    const eventDivisions = props.event.get("event_divisions").filter(ed => !ed.get("is_group"));
    const eventDivisionIds = eventDivisions.reduce((ids, ed) => ({ ...ids, [ed.get("division_id")]: ed }), {});

    const organisationDivisions = props.organisation.get("divisions").reduce((divisions, division) => {
        const eventDivision = eventDivisionIds[division.get("id")];
        if (eventDivision) return divisions.push(eventDivision);
        return divisions.push(Immutable.fromJS({ division_id: division.get("id"), division_name: division.get("name") }));
    }, Immutable.List());

    const initialValues = Immutable.fromJS(
        {
            start_date: dayjs().format(ISO_DATE),
            end_date: dayjs().format(ISO_DATE),
            event_divisions_attributes: eventDivisions.filter(ed => ed.get("id")),
            custom_fields_registrations_attributes: props.event.get("capture_fields"),
        })
        .mergeDeep((props.event
            .set("start_date", dayjs(props.event.get("date")).format(ISO_DATE))
            .set("end_date", dayjs(props.event.get("date")).add(props.event.get("days_window") - 1, "day"))
            .set("jersey_order", (props.event.get("jersey_order") || Immutable.List()).toArray())
            .delete("event_divisions")) || {})
        .update("season_ids", ids =>
            ids || Immutable.List([props.organisation.getIn(["available_series", 0, "id"])]))
        .update("payment_options", paymentOptions =>
            paymentOptions && paymentOptions.update("family_total", mapFamilyTotalToForm))
        .update("registration_options", registrationOptions =>
            registrationOptions && registrationOptions.update("divisions", mapDivisionsToForm))
        .delete("capture_fields");

    return {
        organisationDivisions,
        initialValues,
        organisationId: props.event.get("organisation_id"),
        errors: getFormSyncErrors("event")(state),
        formState: getFormValues("event")(state),
        startDate: selector(state, "start_date"),
        divisions: selector(state, "event_divisions_attributes"),
        localization: state.localization,
    };
})
@reduxForm({ form: "event", enableReinitialize: true, touchOnChange: true,
    onSubmitFail: () => setTimeout(() => {
        const errorEl = document.querySelector(".formField.error input, .formField.error select, .formField.error button");
        errorEl && errorEl.focus && errorEl.focus();
    }) })
export class EventForm extends PureComponent {
    static propTypes = {
        organisation: PropTypes.instanceOf(Immutable.Map).isRequired,
        localization: PropTypes.instanceOf(Immutable.Map).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        event: PropTypes.instanceOf(Immutable.Map),
        formState: PropTypes.instanceOf(Immutable.Map)
    };

    state = {
        selectedTab: new URLSearchParams(this.props.location.search).get("code") ? 3 : 0
    };

    changeTab = (e, tab) => {
        if (this.props.invalid) this.props.handleSubmit();
        else this.setState({ selectedTab: tab });
    };

    tabRenderers = {
        0: EventGeneralForm,
        1: EventAdvancedForm,
        2: EventJudgingForm,
        3: PaymentSetupForm,
        4: RegistrationSetupForm,
        5: RankingsLeaderboardsForm
    };

    render = () => {
        const { handleSubmit, submitting, pristine, event } = this.props,
            { selectedTab } = this.state;

        if (!this.props.formState) return null;

        return (
            <form onSubmit={handleSubmit}>
                <Tabs value={selectedTab}
                      onChange={this.changeTab}
                      variant="scrollable"
                      scrollButtons={false}>

                    <Tab label={<T>General</T>} value={0}/>
                    <Tab label={<T>Advanced</T>} value={1}/>
                    <Tab label={<T>Judging</T>} value={2}/>
                    <Tab label={<T>Payments</T>} value={3}/>
                    <Tab label={<T>Registration</T>} value={4}/>
                    <Tab label={<T>Rankings & leaderboards</T>} value={5}/>
                </Tabs>

                <br/>
                {createElement(this.tabRenderers[selectedTab], this.props)}

                <div className="submission">
                    <LoadingButton type="submit" variant="contained" color="primary"
                                   disabled={pristine || submitting}
                                   action={handleSubmit}>
                        <T>Update event</T>
                    </LoadingButton>

                    <br/><br/>
                    <DeleteEventWidget eventId={event.get("id")}/>
                </div>
            </form>
        );
    };
}

export const EventFormSkeleton = () => null;
