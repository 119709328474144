import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Immutable from "immutable";
import { Field, reduxForm, SubmissionError } from "redux-form/lib/immutable";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

import * as UserActions from "../../actions/user";

import { mustBeEmail, mustHaveLength, plainInput, required } from "../forms";
import { homeForUser } from "../routing/PrivateRoute";
import { OrFacebookLogin } from "../facebook";
import { LoadingButton } from "../actions/loadingButton";
import { AccountFormPage } from "./common";
import { T } from "../util/t";
import { useEnableThirdPartyCookies } from "hooks/useEnableThirdPartyCookies";
import { Notification } from "../layout/notifications/Notification";

const mustHave6 = mustHaveLength(6);

class AccountForm extends PureComponent {
    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        preventRedirect: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
    };

    state = { submitting: false };

    redirectUserAfterSubmit = ({ data: user }) => {
        if (this.props.preventRedirect) return;

        const { location, history } = this.props;
        if (location.state && location.state.from) {
            return history.push(location.state.from);
        }

        return history.push(homeForUser(user));
    };

    onAccessGranted = values => this.props.dispatch(this.action(values)).then(this.redirectUserAfterSubmit).catch(this.onError);
    onAccessDenied = () => this.setState({ submitting: false, error: "failed_login_open_in_external_tab", errorProps: { url: this.props.location?.state?.from?.pathname || window.location.href } });

    render = () =>
        <>
            <FormWrapper handleSubmit={this.props.handleSubmit} onAccessGranted={this.onAccessGranted} onAccessDenied={this.onAccessDenied}>
                {({ onSubmit }) => (
                    <form onSubmit={onSubmit}>
                        {this.state.error && <Notification type="error" className="margin-bottom" text={<T {...this.state.errorProps}>{this.state.error}</T>}/>}

                        {this.fields()}

                        <div className="actions">
                            <LoadingButton
                                variant="contained"
                                action={onSubmit}
                                disabled={this.props.pristine || this.state.submitting}
                                submitting={this.props.submitting || this.state.submitting}
                                type="submit"
                                color="primary">
                                <T>{this.submitLabel}</T>
                            </LoadingButton>
                        </div>
                    </form>
                )}
            </FormWrapper>

            <OrFacebookLogin
                label={this.FBLabel}
                onClick={() => this.setState({ submitting: true })}
                onLogin={this.redirectUserAfterSubmit}
                onError={(e) => {
                    this.setState({
                        submitting: false,
                        error: (e.response && e.response.data && e.response.data.error) || "Oops, something went wrong!"
                    });
                }}
            />

            {this.changePageAction && (
                <div className="actions change-page-action">
                    {this.changePageAction()}
                </div>
            )}
        </>;
}

const FormWrapper = ({ children, handleSubmit, onAccessGranted, onAccessDenied }) => {
    const enableThirdPartyCookies = useEnableThirdPartyCookies(onAccessGranted, onAccessDenied);
    return children({ onSubmit: handleSubmit(enableThirdPartyCookies) });
};

@connect()
@reduxForm({ form: "login", touchOnChange: true })
export class LoginForm extends AccountForm {
    FBLabel = "Log in with Facebook";
    submitLabel = "Log in";
    action = values => UserActions.login(values.get("email"), values.get("password"));

    onError = e =>
        this.setState({
            error: (e.response && e.response.status === 401 && "Incorrect email or password") ||
                "Oops, something went wrong!"
        });

    fields = () =>
        <>
            <Field name="email"
                   label={<T>Email:</T>}
                   type="email"
                   autoComplete="username"
                   component={plainInput}/>

            <Field name="password"
                   label={<T>Password:</T>}
                   type="password"
                   autoComplete="current-password"
                   component={plainInput}/>
        </>;

    changePageAction = () => <MuiLink to="/forgot-password" component={Link} underline="none"><T>Forgot your password?</T></MuiLink>;
}

export const LoginPage = (props) => {
    const handleClick = () => props.history.push("/sign-up");

    return (
        <AccountFormPage title="Log in to Liveheats" actionText="Don't have an account?" actionButtonText="Sign up" actionClick={handleClick}>
            <LoginForm {...props} />
        </AccountFormPage>
    );
};

LoginPage.propTypes = { history: PropTypes.object.isRequired };

@connect()
@reduxForm({ form: "sign-up", touchOnChange: true })
export class SignUpForm extends AccountForm {
    FBLabel = "Sign up with Facebook";
    submitLabel = "Create account";
    action = values => UserActions.register(values.get("email"), values.get("name"), values.get("password"));

    onError = e => {
        if (e.response && e.response.status === 422) {
            this.setState({ error: "email_taken" });
            throw new SubmissionError(Immutable.fromJS(e.response.data.errors).map(e => e.join(", ")).toJS());
        } else {
            this.setState({ error: "Oops, something went wrong!" });
        }
    };

    fields = () =>
        <>
            <Field name="name"
                   label={<T>Full name:</T>}
                   type="text"
                   validate={required}
                   component={plainInput}/>

            <Field name="email"
                   label={<T>Email:</T>}
                   type="email"
                   validate={mustBeEmail}
                   autoComplete="username"
                   component={plainInput}/>

            <Field name="password"
                   label={<T>Password:</T>}
                   type="password"
                   validate={mustHave6}
                   autoComplete="new-password"
                   component={plainInput}/>
        </>;
}

export const SignUpPage = (props) => {
    const handleClick = () => props.history.push("/login");

    return (
        <AccountFormPage title="Create Liveheats account" actionText="Already have an account?" actionButtonText="Log in" actionClick={handleClick}>
            <SignUpForm {...props} />
        </AccountFormPage>
    );
};

SignUpPage.propTypes = { history: PropTypes.object.isRequired };
