import { useEffect, useRef, useState } from "react";
import { useError } from "hooks/useNotifications";
import { useCancellableDebounce } from "hooks/useCancellableDebounce";
import GoogleLogo from "../../../../../assets/icons/google_on_white_hdpi.png";
import { PaperComponent } from "components/layout/forms/fields/Autocomplete/SearchSelect";
import { createFilterOptions, Stack } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import dayjs from "dayjs";
import { ISO_DATE } from "../../../../../utils/dates";
import { api } from "../../../../../actions/api";

export const API_KEY = "AIzaSyBQI1wnQnFEYziAp3aSL8-3Wt37YRAUXB8";

const useStyles = makeStyles(theme => ({
    logoContainer: {
        color: theme.palette.text.lighter,
        font: "Roboto",
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 500,
        margin: theme.spacing(0, 1, 0.75),
        alignItems: "center",
        justifyContent: "end",
    },
    logo: {
        height: theme.spacing(2),
        paddingTop: theme.spacing(0.25),
    }
}));

export const useLocationField = ({ value, dateField, setValue, getValue, getInitialValueLabel }) => {
    const places = useRef();
    const sessionToken = useRef();
    const pendingSearch = useRef();
    const { notifyError } = useError();
    const [clearOnBlur, setClearOnBlur] = useState(false);

    useEffect(() => {
        import(/* webpackChunkName: "@googlemaps/js-api-loader" */ "@googlemaps/js-api-loader").then(({ Loader }) => {
            new Loader({ apiKey: API_KEY, retries: 5 }).importLibrary("places").then(library => {
                places.current = library;
                if (pendingSearch.current) search(pendingSearch.current);
            });
        });
    }, []);

    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(() => value ? [{ label: getInitialValueLabel(value), value }] : []);

    const search = async input => {
        setLoading(true);

        if (!places.current) return pendingSearch.current = input;

        const { AutocompleteSessionToken, AutocompleteSuggestion } = places.current;
        if (!sessionToken.current) sessionToken.current = new AutocompleteSessionToken();
        setOptions([]);

        const request = {
            input,
            sessionToken: sessionToken.current,
            // locationBias: "IP_BIAS",
        };

        return AutocompleteSuggestion.fetchAutocompleteSuggestions(request).then(({ suggestions }) => {
            setLoading(false);
            setOptions(suggestions.map(({ placePrediction }) => ({ label: placePrediction.text.toString(), value: placePrediction })));
        }).catch(e => {
            notifyError("Oops, something went wrong!");
        });
    };
    const debouncedSearch = useCancellableDebounce(search, 400);

    useEffect(() => {
        const isPlacePrediction = typeof value?.toPlace === "function";

        if (isPlacePrediction) {
            value.toPlace().fetchFields({
                fields: ["formattedAddress", "utcOffsetMinutes", "location", "googleMapsURI"],
            }).then(response => {
                const json = response.place.toJSON();
                const { googleMapsURI, utcOffsetMinutes, ...rest } = json;
                setLocationAndTimezone({
                    ...rest,
                    googleMapsUri: googleMapsURI,
                    utcOffsetMinutes: utcOffsetMinutes || 0,
                    placePredictionText: value.text.toString(),
                });
                sessionToken.current = null; // clear session token after each session for billing purposes
            }).catch(error => {
                notifyError("Oops, something went wrong!");
                setValue(null);
            });
        }
    }, [value]);

    const timezoneAbbreviation = timezoneId => timezoneId.split(" ").map(word => word.charAt(0)).join("");

    useEffect(() => {
        const selectedLocation = getValue(value);
        if (!selectedLocation) return;

        setLocationAndTimezone(selectedLocation);
    }, [dateField]);

    const setLocationAndTimezone = (selectedLocation) => {
        if (!selectedLocation) return;

        const lat = selectedLocation?.location?.lat,
            lng = selectedLocation?.location?.lng;

        if (!lat || !lng) return setValue(selectedLocation);

        const timestamp = dayjs(dateField).unix() || dayjs().unix();

        api.fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=${timestamp}&key=${API_KEY}`)
            .then(response => {
                const data = response.data;
                setValue({
                    ...selectedLocation,
                    timezone: {
                        abbreviation: timezoneAbbreviation(data.timeZoneName),
                        dstOffsetMinutes: data.dstOffset ? data.dstOffset / 60 : 0,
                        date: dateField || dayjs().format(ISO_DATE) }
                });
            })
            .catch(() => setValue(selectedLocation));
    };

    const onInputChange = (e, value, reason) => {
        setClearOnBlur(true);
        if (!["selectOption", "reset"].includes(reason)) {
            setValue(null);
            if (value) return debouncedSearch(value);
        }
        if(["selectOption", "reset"].includes(reason)){
            setClearOnBlur(false);
        }
        setOptions([]);
    };

    const classes = useStyles();
    const footer = (
        <Stack direction="row" spacing={0.25} alignItems="center" className={classes.logoContainer}>
            <p>powered by</p>
            <img className={classes.logo} src={GoogleLogo} alt="Google Logo" />
        </Stack>
    );

    const filterOptions = (options, state) => {
        const filtered = createFilterOptions({ trim: true })(options, state);

        if (filtered.length === 0) {
            filtered.push({
                label: "No options",
                disabled: true,
            });
        }

        return filtered;
    };

    return {
        loading,
        options,
        error: "Search for a place or address, and choose from the list.",
        autocompleteProps: { onInputChange, filterOptions, clearOnBlur, freeSolo: true, PaperComponent: (props) => PaperComponent({ footer, ...props }), },
    };
};
