import { Component } from "react";
import Immutable from "immutable";
import { connect } from "react-redux";

import * as OrganisationActions from "../../actions/organisation";
import * as EventActions from "../../actions/event";
import { EventForm, EventFormSkeleton } from "./manage/eventForm";
import { Heading } from "../layout/page/Heading";
import { eventPropertiesMapper } from "./manage/helpers";

@connect((state, props) => ({
    event: state.events.getIn(["mini", parseInt(props.match.params.id)], Immutable.Map()),
    organisation: state.organisations.get("current")
}))
export class EditEvent extends Component {
    componentDidMount = () => this.props.dispatch(OrganisationActions.getCurrentUserOrganisation());

    updateEvent = (values) => {
        const { event, dispatch, history } = this.props;
        return dispatch(EventActions.update(event.get("id"), eventPropertiesMapper(values, event.get("date"))))
            .then(() => history.push(`/events/${event.get("id")}/dashboard`));
    };

    render = () =>
        <div className="wrapper event-form">
            <Heading title="Event settings"/>

            {(this.props.organisation && !this.props.event.isEmpty()) ?
                <EventForm
                    onSubmit={this.updateEvent}
                    event={this.props.event}
                    organisation={this.props.organisation}
                    location={this.props.location} /> :

                <EventFormSkeleton/>
            }
        </div>;
}
