import { Typography, Box } from "@mui/material";
import { T } from "components/util/t";
import { HeadingNav } from "../../../../../layout/navigation/HeadingNav";
import { Link, useParams } from "react-router-dom";
import { useEmailAthletes } from "../EmailAthletesProvider";
import { Notification } from "components/layout/notifications/Notification";

const EmailAthletesHeader = () => {
    const { id } = useParams();
    const { isOnTrial, hasSentEmail, sentEmails } = useEmailAthletes();

    const sentNavLabel = sentEmails ? <T value={sentEmails.length}>email_athletes_sent_heading</T> : "Sent";

    const headingNavLinks = [
        { label: "New message", to: `/events/${id}/email-athletes/new` },
        { label: sentNavLabel, to: `/events/${id}/email-athletes/sent`, disabled: !sentEmails?.length }
    ];

    return (
        <div>
            <Typography variant="h3" component="h1" mt={1} mb={4}>
                <T>Email athletes</T>
            </Typography>

            {isOnTrial &&
            <Box marginBottom={4}>
                <Notification type="info" text="email_athletes_on_trial_notification"
                              buttonProps={{
                                  label: "Purchase credits",
                                  to: "/director/purchase",
                                  component: Link,
                                  target: "_blank"
                              }}
                />
            </Box>}

            {isOnTrial === false && hasSentEmail === false && (
                <Box data-testid="email-athletes-new-feat-notification" marginBottom={4}>
                    <Notification type="info" text="email_athletes_new_feat_notification"
                                  buttonProps={{
                                      label: "Learn more",
                                      href: "https://support.liveheats.com/hc/en-us/articles/360034191412-Sending-an-email-to-your-event-registrants",
                                      target: "_blank"
                                  }}/>
                </Box>
            )}

            <HeadingNav links={headingNavLinks}/>
        </div>
    );
};

export default EmailAthletesHeader;
