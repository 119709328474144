const base = "/api";

const checkStatus = response => {
    if (response.ok) {
        return response;
    } else {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
};

const parse = response => {
    const contentType = response.headers && response.headers.get("content-type");
    return contentType && contentType.includes("application/json") ?
        response.json().then(data => ({ status: response.status, ok: response.ok, data })) :
        Promise.resolve({ status: response.status, ok: response.ok });
};

const CONFIG = {
    credentials: "same-origin",
    headers: {
        "Content-Type": "application/json"
    }
};

export const windowFetch = (url, config) => window.fetch(url, config).then(parse).then(checkStatus);

const BASE_DELAY = 500;
const MAX_DELAY = 5000;

const doRequest = (method, resource, extras = {}, limit = 5) =>
    new Promise((resolve,reject) => {
        let count = 1;
        const failure = e => {
            if (count++ < limit && (!e.response || (e.response && e.response.status > 500))) {
                const delay =  Math.min(MAX_DELAY, Math.random() * BASE_DELAY * Math.pow(2, count));
                setTimeout(doFetch, delay);
            } else {
                reject(e);
            }
        };
        const doFetch = () => windowFetch(`${base}/${resource}`, { ...CONFIG, method, ...extras })
            .then(resolve)
            .catch(failure);

        doFetch();
    });

const doRequestWithBody = (method, resource, body, limit, extraConfig = {}) => doRequest(method, resource, { body: JSON.stringify(body), ...extraConfig }, limit);

export const api = {
    delete: (resource) => doRequest("DELETE", resource),
    get: (resource, extraConfig = {}, limit) => doRequest("GET", resource, extraConfig, limit),
    post: (resource, data, extraConfig, limit) => doRequestWithBody("POST", resource, data, limit, extraConfig),
    put: (resource, data, limit) => doRequestWithBody("PUT", resource, data, limit),
    patch: (resource, data, limit) => doRequestWithBody("PATCH", resource, data, limit),
    postFile: (resource, formData) =>  doRequest("POST", resource, { body: formData, headers: {} }, 1),
    postFileToS3: (url, formData) => windowFetch(url, { method: "POST", body: formData }),
    fetch: (url) => windowFetch(url)
};
