import "./application.less";

import { lazy, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { LazyRoute } from "./routing/LazyRoute";
import { LazyPrivateRoute, PrivateRoute } from "./routing/PrivateRoute";
import { SiteHeader } from "./layout/navigation/SiteHeader";
import { PageContainer } from "./layout/page/PageContainer";
import { Notifications } from "./notifications/notifications";
import { BrowserUpdateDialog } from "./BrowserUpdateDialog/BrowserUpdateDialog";
import { HomePage } from "./routes/root/HomePage";
import { LoginPage, SignUpPage } from "./login/accountForms";
import { ForgotPasswordPage } from "./login/forgotPassword";
import { RecoverPasswordPage } from "./login/recoverPassword";
import { TermsAndConditions } from "./legal/termsAndConditions/TermsAndConditions";
import { PrivacyPolicy } from "./legal/privacyPolicy/PrivacyPolicy";
import { OrganisationsList } from "./organisations/list";
import { JudgeDashboard } from "./dashboards/judge";
import { DnDEditSchedule } from "./events/schedules/editSchedule";
import { JudgePage } from "./events/judging/judgePage";
import { Spectate } from "./events/spectate";
import { EventRoot } from "./events/root";
import { EditEvent } from "./events/edit";
import { EventDashboard } from "./events/dashboard/dashboard";
import { EventHeadJudge } from "./events/judging/headJudge";
import { EditHeatPage } from "./events/heats/editPage";
import { EventDivisionRoot } from "./eventDivisions/root";
import { NotifiedRedirect } from "./routing/NotifiedRedirect";
import { BroadcastEvent, BroadcastEventChromaKey } from "./events/broadcast";

import { PaymentsPageSkeleton } from "./routes/director/PaymentsPageSkeleton";
import { EventPageSkeleton } from "./routes/events/id/EventPageSkeleton";
import { EventDivisionPageSkeleton } from "./routes/events/id/divisions/eventDivisionId/EventDivisionPageSkeleton";
import { SchedulePageSkeleton } from "./routes/events/id/schedule/SchedulePageSkeleton";
import { EventRegistrationPageSkeleton } from "./routes/events/id/registration/EventRegistrationPageSkeleton";
import { SeriesSettingsPageSkeleton } from "./routes/series/SeriesSettingsPageSkeleton";
import { EditAthletePageSkeleton } from "./routes/athletes/athlete_id/edit/EditAthletePageSkeleton";
import { PersonalSettingsPageSkeleton } from "./routes/user/settings/PersonalSettingsPageSkeleton";
import { NFCTagPageSkeleton } from "./routes/athletes/activate-tag/NFCTagPageSkeleton";
import { DirectorPageSkeleton } from "./routes/director/DirectorPageSkeleton";
import { AthletesTeamsPageSkeleton } from "./routes/director/athletes/AthletesTeamsPageSkeleton";
import { NewOrganisationPageSkeleton } from "./routes/organisations/new/NewOrganisationPageSkeleton";
import { EditOrganisationPageSkeleton } from "./routes/organisations/edit/EditOrganisationPageSkeleton";
import { EntriesPageSkeleton } from "./routes/events/id/divisions/eventDivisionId/entries/EntriesPageSkeleton";
import { EventDivisionRootSkeleton } from "./routes/events/id/divisions/eventDivisionId/EventDivisionRootSkeleton";
import { SkeletonTable } from "./layout/skeleton/SkeletonTable";
import { EventDivisionResultPageSkeleton } from "./routes/events/id/divisions/eventDivisionId/result/EventDivisionResultPageSkeleton";
import { DirectorTeamLeaderboardPageSkeleton } from "./routes/events/id/club-leaderboard/DirectorTeamLeaderboardSkeleton";
import { ClubLeaderboardPageSkeleton } from "./routes/events/id/club-leaderboard/ClubLeaderboardPageSkeleton";
import { PaymentDetailsPageSkeleton } from "./routes/director/payments/id/PaymentDetailsPageSkeleton";
import { EditDrawPageSkeleton } from "./routes/events/id/divisions/eventDivisionId/draw/EditDrawPageSkeleton";
import { OrganisationRootSkeleton } from "./routes/shortName/OrganisationRootSkeleton";
import { EventsCalendarPageSkeleton } from "./routes/shortName/events/EventsCalendarPageSkeleton";
import { FederatedOrganisationsPageSkeleton } from "./routes/shortName/federated-organisations/FederatedOrganisationsPageSkeleton";
import { NewRankingsPageSkeleton } from "./routes/shortName/rankings/NewRankingsPageSkeleton";
import { PaddedRankingsPageSkeleton } from "./routes/director/rankings/PaddedRankingsPageSkeleton";
import { OrganisationUsersPageSkeleton } from "./routes/organisations/edit/OrganisationUsersPageSkeleton";
import { PaymentsPageSkeleton as PowerupsPaymentsPageSkeleton } from "./routes/director/powerups/PaymentsPageSkeleton";
import { DirectorOrganisationRootSkeleton } from "./routes/organisations/DirectorOrganisationRootSkeleton";
import { HeroSuccessPageSkeleton } from "./layout/page/HeroSuccessPageSkeleton";
import { PriorityJudgePageSkeleton } from "./routes/events/id/priority_judge/PriorityJudgePageSkeleton";
import { PriorityPageSkeleton } from "./routes/events/id/priority/PriorityPageSkeleton";
import { SeriesRegistrationPageSkeleton } from "./routes/shortName/series/seriesId/sign-on/SeriesRegistrationPageSkeleton";
import { CreateEventPageSkeleton } from "./routes/events/new/CreateEventPageSkeleton";
import { PlanPageSkeleton } from "./routes/director/plan/PlanPageSkeleton";
import { ReportsPageSkeleton } from "./routes/events/id/reports/ReportsPageSkeleton";
import { UserDashboardPageSkeleton } from "./routes/user/athletes/UserDashboardPageSkeleton";
import { UserEventPageSkeleton } from "./routes/user/events/id/UserEventPageSkeleton";
import { PurchasePageSkeleton } from "./routes/director/purchase/PurchasePageSkeleton";
import { UserEventDivisionPageSkeleton } from "./routes/user/events/id/divisions/id/UserEventDivisionPageSkeleton";
import { UserEventAthletePageSkeleton } from "./routes/user/events/id/athletes/id/UserEventAthletePageSkeleton";
import { MembershipsPageSkeleton } from "./routes/shortName/memberships/MembershipsPageSkeleton";
import { EmailAthletesRootSkeleton } from "./routes/events/id/email-athletes/EmailAthletesRootSkeleton";
import { EmailAthletesNewMessagePageSkeleton } from "./routes/events/id/email-athletes/new/EmailAthletesNewMessagePageSkeleton";
import { EmailAthletesSentMessagePageSkeleton } from "./routes/events/id/email-athletes/sent/EmailAthletesSentMessagePageSkeleton";
import { TeamManagerDashboardPageSkeleton } from "./routes/user/team-manager/TeamManagerDashboardPageSkeleton";
import { TeamPageSkeleton } from "./routes/director/teams/id/TeamPageSkeleton";
import { AthleteActivityPageSkeleton } from "./routes/athletes/athlete_id/AthleteActivityPageSkeleton";
import { AthleteDashboardPageSkeleton } from "./routes/athletes/athlete_id/AthleteDashboardPageSkeleton";
import { AthleteMembershipsPageSkeleton } from "./routes/athletes/athlete_id/AthleteMembershipsPageSkeleton";
import { EventEntryManagementPageSkeleton } from "./routes/events/id/entries/EventEntryManagementPageSkeleton";

const PaymentsPage = lazy(() => import(/* webpackChunkName: "director" */"./routes/director/PaymentsPage"));
const PaymentDetails = lazy(() => import(/* webpackChunkName: "director" */"./routes/director/payments/id/PaymentDetailsPage"));
const NewEventRoot = lazy(() => import(/* webpackChunkName: "public-event" */ "./routes/events/id/EventRoot"));
const EventPage = lazy(() => import(/* webpackChunkName: "public-event" */ "./routes/events/id/EventPage"));
const SchedulePage = lazy(() => import(/* webpackChunkName: "public-event" */ "./routes/events/id/schedule/SchedulePage"));
const EventRegistrationPage = lazy(() => import(/* webpackChunkName: "public-event" */ "./routes/events/id/registration/EventRegistrationPage"));
const EventRegistrationSuccessPage = lazy(() => import(/* webpackChunkName: "public-event" */ "./routes/events/id/registration/success/EventRegistrationSuccessPage"));
const EventRegistrationConfirmPage = lazy(() => import(/* webpackChunkName: "public-event" */ "./routes/events/id/registration/confirm/EventRegistrationConfirmPage"));
const NewEventDivisionRoot = lazy(() => import(/* webpackChunkName: "public-event" */ "./routes/events/id/divisions/eventDivisionId/EventDivisionRoot"));
const EventDivisionPage = lazy(() => import(/* webpackChunkName: "public-event" */ "./routes/events/id/divisions/eventDivisionId/EventDivisionPage"));
const EntriesPage = lazy(() => import(/* webpackChunkName: "shared" */ "./routes/events/id/divisions/eventDivisionId/entries/EntriesPage"));
const SeriesSettingsPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/series/SeriesSettingsPage"));
const AthleteDashboardPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/athletes/athlete_id/AthleteDashboardPage"));
const AthleteActivityPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/athletes/athlete_id/AthleteActivityPage"));
const EditAthletePage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/athletes/athlete_id/edit/EditAthletePage"));
const AthleteMembershipsPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/athletes/athlete_id/AthleteMembershipsPage"));
const PersonalSettingsPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/settings/PersonalSettingsPage"));
const NFCTagPage = lazy(() => import(/* webpackChunkName: "nfc-tag" */ "./routes/athletes/activate-tag/NFCTagPage"));
const DirectorPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/DirectorPage"));
const AthletesTeamsPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/athletes/AthletesTeamsPage"));
const TeamsPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/teams/TeamsPage"));
const TeamPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/teams/id/TeamPage"));
const NewOrganisationPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/organisations/new/NewOrganisationPage"));
const DirectorOrganisationRoot = lazy(() => import(/* webpackChunkName: "director" */ "./routes/organisations/edit/DirectorOrganisationRoot"));
const EditOrganisationPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/organisations/edit/EditOrganisationPage"));
const OrganisationUsersPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/organisations/edit/OrganisationUsersPage"));
const PowerupsPaymentsPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/powerups/PaymentsPage"));
const ManageEntriesPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/divisions/eventDivisionId/entries/ManageEntriesPage"));
const EventDivisionTeamLeaderboardPage = lazy(() => import(/* webpackChunkName: "shared" */ "./routes/events/id/divisions/eventDivisionId/team-leaderboard/EventDivisionTeamLeaderboardPage"));
const EventDivisionLeaderboardPage = lazy(() => import(/* webpackChunkName: "shared" */ "./routes/events/id/divisions/eventDivisionId/leaderboard/EventDivisionLeaderboardPage"));
const EventDivisionResultPage = lazy(() => import(/* webpackChunkName: "shared" */ "./routes/events/id/divisions/eventDivisionId/result/EventDivisionResultPage"));
const DirectorTeamLeaderboardPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/club-leaderboard/DirectorTeamLeaderboardPage"));
const ClubLeaderboardPage = lazy(() => import(/* webpackChunkName: "shared" */ "./routes/events/id/club-leaderboard/ClubLeaderboardPage"));
const EditDrawPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/divisions/eventDivisionId/draw/EditDrawPage"));
const OrganisationRoot = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/OrganisationRoot"));
const OrganisationPage  = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/OrganisationPage"));
const SeriesRegistrationPage = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/series/seriesId/sign-on/SeriesRegistrationPage"));
const SeriesRegistrationSuccessPage = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/series/seriesId/sign-on/success/SeriesRegistrationSuccessPage"));
const SeriesRegistrationConfirmPage = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/series/seriesId/sign-on/confirm/SeriesRegistrationConfirmPage"));
const EventsCalendarPage = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/events/EventsCalendarPage"));
const FederatedOrganisationsPage = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/federated-organisations/FederatedOrganisationsPage"));
const NewRankingsPage = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/rankings/NewRankingsPage"));
const PaddedRankingsPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/rankings/PaddedRankingsPage"));
const PriorityJudgePage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/priority_judge/PriorityJudgePage"));
const PriorityPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/priority/PriorityPage"));
const QuantumPage = lazy(() => import(/* webpackChunkName: "quantum" */ "./routes/events/id/quantum/QuantumPage"));
const CreateEventPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/new/CreateEventPage"));
const PlanPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/plan/PlanPage"));
const ReportsPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/reports/ReportsPage"));
const UserDashboardPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/athletes/UserDashboardPage"));
const TeamManagerDashboardPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/team-manager/TeamManagerDashboardPage"));
const UserEventPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/events/id/UserEventPage"));
const UserEventTeamsPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/events/id/UserEventTeamsPage"));
const UserEventAthletesPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/events/id/UserEventAthletesPage"));
const PurchasePage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/purchase/PurchasePage"));
const PurchaseSuccessPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/director/purchase/success/PurchaseSuccessPage"));
const UserEventDivisionPage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/events/id/divisions/id/UserEventDivisionPage"));
const UserEventRoot = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/events/id/UserEventRoot"));
const UserEventAthletePage = lazy(() => import(/* webpackChunkName: "edit-athlete" */ "./routes/user/events/id/athletes/id/UserEventAthletePage"));
const MembershipsPage = lazy(() => import(/* webpackChunkName: "organisation" */ "./routes/shortName/memberships/MembershipsPage"));
const EventEmailAthletesRoot = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/email-athletes/EventEmailAthletesRoot"));
const EmailAthletesNewMessagePage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/email-athletes/new/EmailAthletesNewMessagePage"));
const EmailAthletesSentMessagePage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/email-athletes/sent/EmailAthletesSentMessagePage"));
const SeriesMembersPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/series/id/SeriesMembersPage"));
const EventEntryManagementPage = lazy(() => import(/* webpackChunkName: "director" */ "./routes/events/id/entries/EventEntryManagementPage"));

export const newManageEventPagesPath="/events/:id/(divisions/.*/draw|priority_judge|team-leaderboard|clubs/club-leaderboard|reports|email-athletes|entries)";
export const manageEventPagesPath="/events/:id/(dashboard|edit|head_judge|schedule/.*|division/.*/.*)";
export const judgeTablePagesPath="/events/:id/(head_judge|schedule/heats/.*/edit|division/.*/heats/.*/edit)";

export const Application = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <PageContainer>

            <Notifications/>
            <BrowserUpdateDialog />

            <Route render={props =>
                props.location.pathname.match(/spectate|broadcast|priority$/) ?
                    null :
                    <SiteHeader {...props}/>
            }/>

            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/login" component={LoginPage}/>
                <Route exact path="/forgot-password" component={ForgotPasswordPage}/>
                <Route exact path="/recover-password" component={RecoverPasswordPage}/>
                <Route exact path="/sign-up" component={SignUpPage}/>

                <Route exact path="/terms_and_conditions" component={TermsAndConditions}/>
                <Route exact path="/privacy_policy" component={PrivacyPolicy}/>

                <Route exact path="/organisations" component={OrganisationsList}/>
                <Route exact path="/organizations" component={OrganisationsList}/>

                <LazyRoute exact fallback={NFCTagPageSkeleton} path="/athletes/activate-tag" component={NFCTagPage}/>

                <LazyPrivateRoute fallback={AthleteDashboardPageSkeleton} path="/athletes/:athleteId?">
                    <AthleteDashboardPage>
                        <Switch>
                            <LazyPrivateRoute exact fallback={AthleteActivityPageSkeleton} path="/athletes/:athleteId" component={AthleteActivityPage}/>
                            <LazyPrivateRoute exact fallback={EditAthletePageSkeleton} path="/athletes/:athleteId/edit" component={EditAthletePage}/>
                            <LazyPrivateRoute exact fallback={AthleteMembershipsPageSkeleton} path="/athletes/:athleteId/memberships" component={AthleteMembershipsPage}/>
                            <NotifiedRedirect message="It looks like we can't find what you are looking for!" to="/athletes"/>
                        </Switch>
                    </AthleteDashboardPage>
                </LazyPrivateRoute>

                <LazyPrivateRoute exact fallback={UserDashboardPageSkeleton} path="/user/athletes" component={UserDashboardPage}/>
                <LazyPrivateRoute exact fallback={TeamManagerDashboardPageSkeleton} path="/user/team-manager" component={TeamManagerDashboardPage}/>
                <LazyPrivateRoute exact fallback={PersonalSettingsPageSkeleton} path="/user/settings" component={PersonalSettingsPage}/>

                <LazyPrivateRoute path="/user/events/:id/(.+)" fallback={UserEventPageSkeleton}>
                    <UserEventRoot>
                        <Switch>
                            <LazyPrivateRoute exact path="/user/events/:id/divisions/:eventDivisionId" component={UserEventDivisionPage} fallback={UserEventDivisionPageSkeleton}  />
                            <LazyPrivateRoute exact path="/user/events/:id/athletes/:athleteId" component={UserEventAthletePage} fallback={UserEventAthletePageSkeleton} />
                            <LazyPrivateRoute path="/user/events/:id/(teams|athletes)" fallback={UserEventPageSkeleton}>
                                <UserEventPage>
                                    <Switch>
                                        <LazyPrivateRoute exact path="/user/events/:id/teams" component={UserEventTeamsPage} fallback={UserEventPageSkeleton} />
                                        <LazyPrivateRoute exact path="/user/events/:id/athletes" component={UserEventAthletesPage} fallback={UserEventPageSkeleton} />
                                    </Switch>
                                </UserEventPage>
                            </LazyPrivateRoute>
                        </Switch>
                    </UserEventRoot>
                </LazyPrivateRoute>

                <LazyPrivateRoute exact fallback={NewOrganisationPageSkeleton} path="/organisations/new" component={NewOrganisationPage} signUp/>
                <LazyPrivateRoute path="/organisation/edit/(profile|users)" fallback={DirectorOrganisationRootSkeleton}>
                    <DirectorOrganisationRoot>
                        <Switch>
                            <LazyPrivateRoute exact path="/organisation/edit/profile" component={EditOrganisationPage} fallback={EditOrganisationPageSkeleton} role="director"/>
                            <LazyPrivateRoute exact path="/organisation/edit/users" component={OrganisationUsersPage} fallback={OrganisationUsersPageSkeleton} role="director"/>
                        </Switch>
                    </DirectorOrganisationRoot>
                </LazyPrivateRoute>
                <LazyPrivateRoute exact path="/director/powerups/payments" component={PowerupsPaymentsPage} fallback={PowerupsPaymentsPageSkeleton} role="director"/>

                <LazyPrivateRoute exact fallback={DirectorPageSkeleton} path="/director" component={DirectorPage} role="director"/>
                <LazyPrivateRoute exact fallback={PaddedRankingsPageSkeleton} path="/director/rankings" component={PaddedRankingsPage} role="director"/>
                <LazyPrivateRoute exact fallback={AthletesTeamsPageSkeleton} path="/director/athletes-teams" component={AthletesTeamsPage} role="director"/>
                <LazyPrivateRoute exact fallback={AthletesTeamsPageSkeleton} path="/director/teams" component={TeamsPage} role="director"/>
                <LazyPrivateRoute exact fallback={TeamPageSkeleton} path="/director/teams/:teamId" component={TeamPage} role="director"/>
                <LazyPrivateRoute exact fallback={PaymentsPageSkeleton} path="/director/payments" component={PaymentsPage} role="director"/>
                <LazyPrivateRoute exact fallback={PaymentDetailsPageSkeleton} path="/director/payments/:id" component={PaymentDetails} role="director"/>
                <LazyPrivateRoute exact fallback={PlanPageSkeleton} path="/director/plan" component={PlanPage} role="director"/>
                <LazyPrivateRoute exact fallback={PurchasePageSkeleton} path="/director/purchase" component={PurchasePage} role="director"/>
                <LazyPrivateRoute exact fallback={HeroSuccessPageSkeleton} path="/director/purchase/success" component={PurchaseSuccessPage} role="director"/>

                <LazyPrivateRoute exact fallback={PaddedRankingsPageSkeleton} path="/series/:series_id/members" component={SeriesMembersPage} role="director"/>
                <LazyPrivateRoute exact fallback={SeriesSettingsPageSkeleton} path="/series/new" component={SeriesSettingsPage} role="director"/>
                <LazyPrivateRoute exact fallback={SeriesSettingsPageSkeleton} path="/series/:id/edit" component={SeriesSettingsPage} role="director"/>

                <PrivateRoute exact path="/dashboard/judge" component={JudgeDashboard} role="judge"/>

                <LazyPrivateRoute exact fallback={CreateEventPageSkeleton} path="/events/new" component={CreateEventPage} role="director"/>
                <PrivateRoute exact path="/events/:id/scoring" component={JudgePage} role="judge"/>

                <LazyRoute exact path="/events/:id/priority" fallback={PriorityPageSkeleton} component={PriorityPage}/>
                <Route exact path="/events/:id/broadcast" component={BroadcastEvent}/>
                <Route exact path="/events/:id/broadcast/chromakey" component={BroadcastEventChromaKey}/>
                <Route path="/events/:id/(dashboard|edit|head_judge|spectate|schedule/.*|division/.*/.*)" render={props =>
                    <EventRoot {...props}>
                        <Switch>
                            <Route exact path="/events/:id/spectate" component={Spectate}/>

                            <PrivateRoute exact path="/events/:id/edit" component={EditEvent} role="director"/>
                            <PrivateRoute exact path="/events/:id/dashboard" component={EventDashboard} role="director"/>
                            <PrivateRoute exact path="/events/:id/head_judge" component={EventHeadJudge} role="director"/>
                            <PrivateRoute exact path="/events/:id/schedule/edit" component={DnDEditSchedule} role="director"/>
                            <PrivateRoute exact path="/events/:id/schedule/heats/:heat_id/edit" component={EditHeatPage} role="director"/>
                            <PrivateRoute exact path="/events/:id/division/:event_division_id/heats/:heat_id/edit" component={EditHeatPage} role="director"/>

                            <Route path="/events/:id/division/:event_division_id" render={props =>
                                <EventDivisionRoot {...props}>
                                    <Switch>
                                        <LazyPrivateRoute exact fallback={EntriesPageSkeleton} path="/events/:id/division/:event_division_id/entries" component={ManageEntriesPage} role="director"/>
                                        <LazyPrivateRoute exact fallback={EventDivisionPageSkeleton} path="/events/:id/division/:event_division_id/leaderboard" component={EventDivisionLeaderboardPage} role="director"/>
                                        <LazyPrivateRoute exact fallback={SkeletonTable} path="/events/:id/division/:event_division_id/team-leaderboard" component={EventDivisionTeamLeaderboardPage} role="director"/>
                                        <LazyPrivateRoute exact fallback={EventDivisionResultPageSkeleton} path="/events/:id/division/:event_division_id/result" component={EventDivisionResultPage} role="director"/>

                                        <NotifiedRedirect message="It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?" to="/organisations"/>
                                    </Switch>
                                </EventDivisionRoot>
                            }/>
                        </Switch>
                    </EventRoot>
                }/>

                <LazyRoute path="/events/:id" fallback={EventPageSkeleton}>
                    <NewEventRoot>
                        <Switch>
                            <LazyRoute exact path="/events/:id" fallback={EventPageSkeleton} component={EventPage}/>
                            <LazyRoute exact path="/events/:id/embed" fallback={EventPageSkeleton} component={EventPage}/>
                            <LazyRoute exact path="/events/:id/schedule" fallback={SchedulePageSkeleton} component={SchedulePage}/>
                            <LazyRoute exact path="/events/:id/registration" fallback={EventRegistrationPageSkeleton} component={EventRegistrationPage}/>
                            <LazyPrivateRoute exact path="/events/:id/registration/success" fallback={HeroSuccessPageSkeleton} component={EventRegistrationSuccessPage}/>
                            <LazyPrivateRoute exact path="/events/:id/registration/confirm" fallback={EventRegistrationPageSkeleton} component={EventRegistrationConfirmPage}/>
                            <LazyRoute exact path="/events/:id/club-leaderboard" fallback={ClubLeaderboardPageSkeleton} component={ClubLeaderboardPage}/>
                            <LazyPrivateRoute exact fallback={PriorityJudgePageSkeleton} path="/events/:id/priority_judge" component={PriorityJudgePage} role="director"/>
                            <LazyPrivateRoute exact fallback={() => null} path="/events/:id/quantum" component={QuantumPage} role="director"/>
                            <LazyPrivateRoute exact fallback={DirectorTeamLeaderboardPageSkeleton} path="/events/:id/clubs/club-leaderboard" component={DirectorTeamLeaderboardPage} role="director"/>
                            <LazyPrivateRoute exact path="/events/:id/reports" fallback={ReportsPageSkeleton} component={ReportsPage} role="director"/>
                            <LazyPrivateRoute exact path="/events/:id/entries" fallback={EventEntryManagementPageSkeleton} component={EventEntryManagementPage} role="director"/>

                            <LazyRoute path="/events/:id/email-athletes/(new|sent)" fallback={EmailAthletesRootSkeleton}>
                                <EventEmailAthletesRoot>
                                    <Switch>
                                        <LazyPrivateRoute exact path="/events/:id/email-athletes/new" fallback={EmailAthletesNewMessagePageSkeleton} component={EmailAthletesNewMessagePage} role="director"/>
                                        <LazyPrivateRoute exact path="/events/:id/email-athletes/sent" fallback={EmailAthletesSentMessagePageSkeleton} component={EmailAthletesSentMessagePage} role="director"/>
                                    </Switch>
                                </EventEmailAthletesRoot>
                            </LazyRoute>

                            <LazyRoute path="/events/:id/divisions/:eventDivisionId" fallback={EventDivisionRootSkeleton}>
                                <NewEventDivisionRoot>
                                    <Switch>
                                        <LazyRoute exact path="/events/:id/divisions/:eventDivisionId" fallback={EventDivisionPageSkeleton} component={EventDivisionPage}/>
                                        <LazyRoute exact path="/events/:id/divisions/:eventDivisionId/entries" fallback={EntriesPageSkeleton} component={EntriesPage}/>
                                        <LazyRoute exact path="/events/:id/divisions/:eventDivisionId/leaderboard" fallback={EventDivisionPageSkeleton} component={EventDivisionLeaderboardPage}/>
                                        <LazyRoute exact path="/events/:id/divisions/:eventDivisionId/team-leaderboard" fallback={SkeletonTable} component={EventDivisionTeamLeaderboardPage}/>
                                        <LazyRoute exact path="/events/:id/divisions/:eventDivisionId/result" fallback={EventDivisionResultPageSkeleton} component={EventDivisionResultPage}/>
                                        <LazyPrivateRoute exact path="/events/:id/divisions/:eventDivisionId/draw" fallback={EditDrawPageSkeleton} component={EditDrawPage} role="director"/>

                                        <NotifiedRedirect message="It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?" to="/organisations"/>
                                    </Switch>
                                </NewEventDivisionRoot>
                            </LazyRoute>

                            <NotifiedRedirect message="It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?" to="/organisations"/>
                        </Switch>
                    </NewEventRoot>
                </LazyRoute>

                <LazyRoute path="/:shortName" fallback={OrganisationRootSkeleton}>
                    <OrganisationRoot>
                        <Switch>
                            <LazyRoute exact path="/:shortName" fallback={OrganisationRootSkeleton} component={OrganisationPage}/>
                            <LazyRoute exact path="/:shortName/events" fallback={EventsCalendarPageSkeleton} component={EventsCalendarPage}/>
                            <LazyRoute exact path="/:shortName/federated-organisations" fallback={FederatedOrganisationsPageSkeleton} component={FederatedOrganisationsPage}/>
                            <LazyRoute exact path="/:shortName/rankings" fallback={NewRankingsPageSkeleton} component={NewRankingsPage}/>
                            <LazyRoute exact path="/:shortName/memberships" fallback={MembershipsPageSkeleton} component={MembershipsPage}/>

                            <LazyRoute exact path="/:shortName/series/:seriesId/sign-on" fallback={SeriesRegistrationPageSkeleton} component={SeriesRegistrationPage}/>
                            <LazyPrivateRoute exact path="/:shortName/series/:seriesId/sign-on/success" fallback={HeroSuccessPageSkeleton} component={SeriesRegistrationSuccessPage}/>
                            <LazyPrivateRoute exact path="/:shortName/series/:seriesId/sign-on/confirm" fallback={SeriesRegistrationPageSkeleton} component={SeriesRegistrationConfirmPage}/>

                            <NotifiedRedirect message="It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?" to="/organisations"/>
                        </Switch>
                    </OrganisationRoot>
                </LazyRoute>
            </Switch>
        </PageContainer>
    );
};
